import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import CustomerKnifeImage from "assets/images/customerKnife.png";
import CloseIcon from "assets/svgs/close-btn.svg";
import RichText from "components/rich-text";
import Image from "components/image";

import {
  modalCloseResponsive, customerKnife, customerKnifeLayer,
  modalCloseDesktop, customerKnifeBody, modalClose,
  modalParagraph, customerKnifeLayers, customerKnifeImgResponsive,
  customerKnifeTitle, customerKnifeImg, customerKnifeHead,
  customServiceModal, modalContainer
} from "./style.mod.scss";

const CustomerKnife = ({ data, children }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const mData = data?.modal && data.modal[0];
  if (!data || !mData) return <></>;

  return (
    <div className={customerKnife}>
      <div className={modalContainer} onClick={handleShow}>
        { children }
      </div>

      <Modal show={show} onHide={handleClose} className={customServiceModal}>
        <CloseIcon className={`${modalClose} ${modalCloseDesktop}`} onClick={handleClose} />
        <Modal.Body>
          <CloseIcon className={`${modalClose} ${modalCloseResponsive}`} onClick={handleClose} />
          <div className={customerKnifeBody}>
            <div className={customerKnifeHead}>
              <RichText
                blocks={mData.title}
              />
            </div>
            <div className={customerKnifeLayers}>
              <Image image={mData.image} alt={mData?.image?.alt} className={`${customerKnifeImg} ${customerKnifeImgResponsive}`} />
              {
                mData?.features?.map((_feat, index) => (
                  <div key={index} className={customerKnifeLayer} >
                    <h4 className={customerKnifeTitle}>{ _feat.title }</h4>
                    <p className={modalParagraph}>{ _feat.details }</p>
                  </div>
                ))
              }
            </div>
          </div>
        </Modal.Body>
        <img src={CustomerKnifeImage} className={customerKnifeImg} />
      </Modal>
    </div>
  );
};

export default CustomerKnife;
