// extracted by mini-css-extract-plugin
export var customServiceModal = "style-mod--custom-service-modal--3Qa1m";
export var modalClose = "style-mod--modal-close--2suJq";
export var modalCloseDesktop = "style-mod--modal-close-desktop--3n9EK";
export var modalCloseDesktopDesktop = "style-mod--modal-close-desktop-desktop--1RMkW";
export var modalCloseDesktopResponsive = "style-mod--modal-close-desktop-responsive--3qYVu";
export var modalCloseResponsive = "style-mod--modal-close-responsive--143tL";
export var modalParagraph = "style-mod--modal-paragraph--1SNnZ";
export var modalTitle = "style-mod--modal-title--1XGwo";
export var customerKnifeTitle = "style-mod--customer-knife-title--2E3wM";
export var customerKnifeHead = "style-mod--customer-knife-head--3PDmY";
export var customerKnifeLayers = "style-mod--customer-knife-layers--3NS1L";
export var customerKnifeLayer = "style-mod--customer-knife-layer--2hY_M";
export var customerKnifeImg = "style-mod--customer-knife-img--2euCi";
export var customerKnifeImgResponsive = "style-mod--customer-knife-img-responsive--35-Dp";
export var customerKnifeBody = "style-mod--customer-knife-body--2Pgk8";